@use "../variables/defaults";

#resume {
  width: 100%;
}

#resume,
#resume>tbody>tr>td {
  border-style: none;
  padding: 0;
}

.resume-heading {
  margin-bottom: 0;
  font-weight: bold;
}

.major-row {
  background-color: white !important;
  border-bottom: 3px solid defaults.$theme-color-1;
  padding-right: 10px;
  vertical-align: top;
  margin-bottom: 10px;
}

.title-box {
  float: left;
  margin-right: 10px;
}

.date-box {
  float: right;
}

.minor-row-title {
  display: flow-root;
  background-color: white !important;
  vertical-align: top;
}

.minor-row-desc {
  background-color: white !important;
}

.header-row {
  text-align: center;
}

.header-text {
  padding: 0;
  margin: 0;
}

.header-title {
  font-weight: bold;
  margin: 0;
}

.no-dot-list {
  list-style: none;
}

@include defaults.media-query(defaults.$on-palm) {
  .minor-row-title {
    display: inline-block;
    background-color: white !important;
    vertical-align: top;
    clear: left;
    width: 100%;
    margin-bottom: 10px;
  }

  .title-box {
    float: left;
    clear: left;
  }

  .date-box {
    float: right;
    clear: left;
  }
}