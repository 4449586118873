@use "sass:color";

// Define defaults for each variable.

$button-color: #DEDEDE;
$button-color-hover: #FFF;
$button-color-outline: #000509;
$button-text-color: #000;
$button-shadow-bold: 0 0 0 2pt $button-color-outline;
$button-shadow-minimum: 0 0 1pt 1pt $button-color-outline;

$theme-color-0: #baffd8;
$theme-color-1: #bae1ff;
$theme-color-2: #ffbae1;
$theme-color-3: #ffd8ba;

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$text-color: #000 !default;
$background-color: #fdfdfd !default;
$brand-color: #003d6c !default;

$grey-color: #222 !default;
$grey-color-light: color.adjust($grey-color, $lightness: 40%) !default;
$grey-color-dark: color.adjust($grey-color, $lightness: -25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: calc($spacing-unit / 2);
//     padding-left: calc($spacing-unit / 2);
//   }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin relative-font-size($ratio) {
    font-size: $base-font-size * $ratio;
}